<template>
    <b-card>
        <b-row class="mb-2">
            <b-col md="4" class="my-1">
                <b-input-group class="repairs-filter">
                    <template #prepend><b-input-group-text>Site</b-input-group-text></template>
                    <select id="repairs-filter-site"><option></option></select>
                </b-input-group>
            </b-col>
            <b-col md="4" class="my-1">
                <b-input-group class="repairs-filter">
                    <template #prepend><b-input-group-text>Unit</b-input-group-text></template>
                    <select id="repairs-filter-unit"><option></option></select>
                </b-input-group>
            </b-col>
        </b-row>
        <b-row class="mb-2">
            <b-col md="3" class="my-1">
                <b-input-group size="sm" prepend="Show" append="rows">
                    <b-form-select v-model="repairs.itemsPerPage" id="perPageSelect" size="sm" :options="repairs.pageOptions"
                                   style="max-width: 5em"></b-form-select>
                </b-input-group>
            </b-col>
        </b-row>
        <b-table :items="loadData" :fields="repairs.fields" :filter="repairs.filter" :current-page="repairs.currentPage"
                 :per-page="repairs.itemsPerPage" empty-text="Sin registros" empty-filtered-text="Sin coincidencias"
                 striped bordered hover small show-empty :responsive="true" :sort-by="repairs.sortBy" :sort-desc="repairs.sortDesc"
                 ref="repairsTable" class="text-left mb-0" @context-changed="tableContextChanged">
            <template #thead-top>
                <b-tr class="table-filters">
                    <b-th v-for="(field, index) in repairs.fields" :key="field.key"
                          :class="repairs.filters[index] && repairs.filters[index].value && typeof repairs.filters[index].value === 'string' ? 'non-empty' : ''">
                        <b-row v-if="repairs.filters[index]">
                            <b-col v-if="repairs.filters[index].type === 'STRING'">
                                <b-input :placeholder="field.label" v-model="repairs.filters[index].value" debounce="300"></b-input>
                            </b-col>
                            <b-col v-else-if="repairs.filters[index].type === 'Select'">
                                <b-select v-model="repairs.filters[index].value">
                                    <b-select-option value="ALL">All</b-select-option>
                                    <b-select-option value="Shipped">Shipped</b-select-option>
                                    <b-select-option value="Pack for Shipment">Pack for Shipment</b-select-option>
                                    <b-select-option value="Not arrived">Not arrived</b-select-option>
                                    <b-select-option value="Arrived & Induction">Arrived & Induction</b-select-option>
                                    <b-select-option value="Inspection">Inspection</b-select-option>
                                    <b-select-option value="Assembly">Assembly</b-select-option>
                                </b-select>
                            </b-col>
                        </b-row>
                    </b-th>
                </b-tr>
            </template>

            <template #cell(geRepairJob)="row">
                <router-link :to="{ name: 'RepairShow', params: { id: 1 }}">{{ row.item.geRepairJob }}</router-link>
            </template>

            <template #cell(actions)="row">
                <b-button-group size="sm">
                    <b-button size="sm" :to="{ name: 'RepairShow', params: { id: row.item.id }}" v-b-tooltip.hover
                              title="Detalle" variant="outline-primary">
                        <font-awesome-icon icon="search"></font-awesome-icon>
                    </b-button>
                </b-button-group>
            </template>

            <template #table-caption>
                <b-row class="no-gutters">
                    <b-col sm="5" md="6">
                        Showing {{ start }} to {{ end }} of {{ repairs.totalRows }} row<template v-if="repairs.totalRows !== 1">s</template>
                    </b-col>
                    <b-col sm="7" md="6" class="my-1">
                        <b-pagination v-model="repairs.currentPage" :total-rows="repairs.totalRows"
                                      :per-page="repairs.itemsPerPage" align="right" class="mb-0"></b-pagination>
                    </b-col>
                </b-row>
            </template>
        </b-table>
    </b-card>
</template>

<script>
import tableStateMixin from '../../mixins/tableState';
import * as constants from '@constants';
import { repairListUrl, siteDataUrl, unitDataUrl } from '@routes';
import $ from 'jquery';

export default {
    mixins: [tableStateMixin],
    data() {
        return {
            repairs: {
                fields: [
                    { key: 'repairJob', label: 'GE Repair Job #', sortable: true },
                    { key: 'cspNumber', label: 'CSP Number', sortable: true },
                    { key: 'partNumber', label: 'GE Part Number', sortable: true },
                    { key: 'component', label: 'Component', sortable: true },
                    { key: 'repairShop', label: 'Repair Shop', sortable: true },
                    { key: 'partReceivedAtRepair', label: 'Parts Received at Repair', sortable: true },
                    { key: 'fplPO', label: 'FPL PO', sortable: true },
                    { key: 'unitText', label: 'Unit ', sortable: true },
                    { key: 'nextUnit', label: 'Next unit ', sortable: true },
                    { key: 'comments', label: 'Comments', sortable: true },
                    { key: 'gate', label: 'Status', sortable: true },
                    { key: 'actions', label: 'Actions', class: 'text-center' }
                ],
                filters: [
                    { column: 'repairJob', type: 'STRING', value: '' },
                    { column: 'cspNumber', type: 'STRING', value: '' },
                    { column: 'partNumber', type: 'STRING', value: '' },
                    { column: 'component', type: 'STRING', value: '' },
                    { column: 'repairShop', type: 'STRING', value: '' },
                    { column: 'partReceivedAtRepair', type: 'STRING', value: '' },
                    { column: 'fplPO', type: 'STRING', value: '' },
                    { column: 'unit', type: 'STRING', value: '' },
                    { column: 'nextUnit', type: 'STRING', value: '' },
                    { column: 'comments', type: 'STRING', value: '' },
                    { column: 'gate', type: 'Select', value: 'ALL' },
                    { column: 'locationFilter', type: 'NA', value: null }
                ],
                filter: '',
                isLoading: false,
                pageOptions: [25, 50, 75, 100],
                totalRows: 0,
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 'b.name',
                sortDesc: false,
                site: null
            },
            breadcrumb: {
                title: 'Repairs',
                path: []
            },
            authorization: `Bearer ${localStorage.getItem('token')}`
        };
    },
    mounted() {
        $('#repairs-filter-site').select2({
            placeholder: 'Site',
            allowClear: true,
            ajax: {
                headers: {
                    'Authorization': this.authorization
                },
                url: siteDataUrl(),
                dataType: 'json',
                method: 'GET',
                data(params) {
                    return { search: params.term };
                },
                processResults(response) {
                    return { results: response.data };
                }
            },
            escapeMarkup: markup => markup,
            templateResult(data) {
                if (data.loading) {
                    return '<a><span>Buscando ...</span></a>';
                }

                return `<strong>${ data.name }</strong>`;
            },
            templateSelection(data) {
                return `<strong>${ data.text || data.name }</strong>`;
            }
        }).on('select2:select', event => {
            let siteData = event.params.data;

            this.repairs.filters[11].value = {
                type: 'SITE',
                id: siteData.id,
                name: siteData.name
            };

            this.repairs.site = {
                id: siteData.id,
                text: siteData.name
            };

            this.initUnitSelect2();
        }).on('select2:unselect', () => {
            this.repairs.site = null;
            this.repairs.filters[11].value = null;

            this.initUnitSelect2();
        });

        this.initUnitSelect2();

        this.$emit('update-breadcrumb', this.breadcrumb);
        this.restoreTableState(this.repairs, this.tableState);
    },
    methods: {
        normalizeText(text) {
            if (text) {
                return text.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u0301]/g, '').normalize();
            }

            return '';
        },
        loadData(ctx) {
            if (ctx.sortBy !== '') {
                let data = {
                    filters: this.repairs.filters.filter(filter => filter.value && ((typeof filter.value === 'string' && filter.value.trim() !== '') || typeof filter.value === 'object')),
                    ...ctx
                };

                data.currentPage = (data.currentPage - 1) * data.perPage;

                return this.axios.post(repairListUrl(), data).then(response => {
                    if (response.data.code === constants.CODE_OK) {
                        this.repairs.totalRows = response.data.totalRows;
                        return response.data.repairs;
                    } else {
                        return [];
                    }
                }).catch(error => {
                    console.error(error);

                    return [];
                });
            }
        },
        tableContextChanged(context) {
            this.repairs.sortBy = context.sortBy;
            this.repairs.sortDesc = context.sortDesc;

            this.saveTableState(this.$route.name, this.repairs);
        },
        initUnitSelect2() {
            let $this = this;

            if ($('#repairs-filter-unit').hasClass('select2-hidden-accessible')) {
                $('#repairs-filter-unit').empty().append('<option></option>').select2('destroy');
            }

            $('#repairs-filter-unit').select2({
                placeholder: 'Unit',
                allowClear: true,
                ajax: {
                    headers: {
                        'Authorization': this.authorization
                    },
                    url: unitDataUrl(),
                    dataType: 'json',
                    method: 'GET',
                    data(params) {
                        return {
                            search: params.term,
                            siteId: $this.repairs.site ? $this.repairs.site.id : 0
                        };
                    },
                    processResults(response) {
                        return { results: response.data };
                    }
                },
                templateResult(data) {
                    if (!data.id) {
                        return data.text;
                    }

                    return `<strong>${ data.unitText }</strong>`;
                },
                templateSelection(data) {
                    return `<strong>${ data.text || data.unitText }</strong>`;
                },
                escapeMarkup(markup) { return markup; }
            }).on('select2:select', event => {
                let eventData = event.params.data;

                this.repairs.filters[11].value = {
                    type: 'UNIT',
                    id: eventData.id,
                    name: eventData.name,
                    alias: eventData.alias
                };

                if (eventData && eventData.siteId) {
                    this.repairs.site = {
                        id: eventData.siteId,
                        name: eventData.siteName
                    };

                    $('#repairs-filter-site').append(new Option(`<strong>${ eventData.siteName }</strong>`, eventData.siteId, true, true)).trigger('change');
                }

                $('#repairs-filter-warehouse').val('').trigger('change');
                $('#repairs-filter-repair').val('').trigger('change');
            }).on('select2:unselect', () => {
                this.repairs.filters[11].value = null;

                if (this.repairs.site) {
                    this.repairs.filters[11].value = {
                        type: 'SITE',
                        id: this.repairs.site.id,
                        text: this.repairs.site.text
                    };
                }
            });
        }
    },
    watch: {
        'repairs.filters': {
            handler() {
                this.repairs.currentPage = 1;
                this.$refs.repairsTable.refresh();
                this.saveTableState(this.$route.name, this.repairs);
            },
            deep: true
        }
    },
    computed: {
        start: function() {
            return this.repairs.totalRows === 0 ? 0 : (this.repairs.currentPage - 1) * this.repairs.itemsPerPage + 1;
        },
        end: function() {
            let offset = (this.repairs.currentPage - 1) * this.repairs.itemsPerPage;

            return (this.repairs.totalRows - offset) < this.repairs.itemsPerPage ? this.repairs.totalRows : offset + this.repairs.itemsPerPage;
        }
    }
};
</script>
<style>
    .repairs-filter {
        flex-wrap: unset;
    }
</style>